import styled from "styled-components"
import { ArrowUpCircleFill as Up } from "@styled-icons/bootstrap/ArrowUpCircleFill"

export const Arrow = styled(Up).attrs(({ direction, size }) => ({
  as: direction === `up` && Up,
  size,
}))`
  ${({ theme, show, size }) => `
  z-index: 2;
  ${"" /* background: ${theme.black}; */}
  background: var(--clr-primary-5);
  ${"" /* color: ${theme.white}; */}
  color: var(--clr-secondary-5);
  border-radius: 50%;
  transition: ${theme.shortTrans};
  position: fixed;
  bottom: 2vh;
  opacity: ${show ? 1 : 0};
  visibility: ${show ? `visible` : `hidden`};
  :hover {
    transition: 0.3s;
    ${"" /* background: ${theme.noBack}; */}
    background: var(--clr-secondary-5);
    color: var(--clr-primary-5);
    cursor:pointer;
  }
  right: calc(1.1vw - ${size} / 1);`}
`
