import React from "react"
import { Link } from "gatsby"
import Title from "./Title"
import styled from "styled-components"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

const slugifyPost = require("../../slugifyPost")

const Movies = ({ movies: data, title, page }) => {
  const [movies] = React.useState(data)
  // more logic with setPoems-el....
  // console.log(poems)

  // console.log("Poemsjs  data", data)
  // console.log("Poems.js pagecontext", pageContext)

  return (
    <Wrapper className="section">
      <Title title={title || "Videók"} />

      <div className="section-center">
        {movies.map(item => {
          const { contentful_id, videoTitle, performer, videoText } = item

          const image = getImage(item.videoImage)

          return (
            <article key={contentful_id} className="card">
              <Link
                // to={`/leftover/poems/${slugifyPost(poems_title)}`}
                to={`/video/${slugifyPost(
                  performer.performerName
                )}-${slugifyPost(videoTitle)}`}
                className="slug"
              >
                <GatsbyImage
                  image={image}
                  className="img"
                  alt="Concert Image"
                />

                <div className="info">
                  <p
                    className=""
                    dangerouslySetInnerHTML={{
                      __html: videoText.childMarkdownRemark.excerpt,
                    }}
                  ></p>
                </div>

                <h3>{videoTitle}</h3>
                <p>{performer.performerName}</p>
              </Link>
            </article>
          )
        })}
      </div>
    </Wrapper>
  )
}

const Wrapper = styled.section`
  background: var(--clr-dark-5);
  padding-top: 3rem;

  .section-center {
    margin-top: 4rem;
    max-width: var(--max-width);
    display: grid;
    gap: 2rem;
    /* safari workaround */
    grid-gap: 2rem;

    .card {
      box-shadow: var(--light-shadow);
      border-radius: var(--radius);
      transition: var(--transition);
      position: relative;
      overflow: hidden;
      border-radius: var(--radius);
      background: var(--clr-primary-5);

      &:hover {
        box-shadow: var(--dark-shadow);
      }

      &:hover .img {
        opacity: 0.2;
      }

      .slug {
        display: block;
        width: 9rem;
        text-align: center;
        margin: 0 auto;
        width: auto;

        .img {
          ${"" /* height: 20rem; */}
          border-radius: var(--radius);
          transition: var(--transition);
        }

        .info {
          padding: 1rem 0.5rem 0.5rem 0.5rem;
          background-color: var(--clr-dark-opacity);
          position: absolute;
          top: 40%;
          left: 50%;
          transform: translate(-50%, -50%);
          width: 100%;
          opacity: 0;
          transition: var(--transition);
          text-align: center;
          p {
            margin-bottom: 0.5rem;
            ${"" /* color: var(--clr-light-5); */}
            ${"" /* text-transform: uppercase; */}
          }
        }
        &:hover .info {
          opacity: 1;
        }
      }
      h3 {
        text-decoration: none;
        text-align: center;
        padding-top: 1rem;
        color: var(--clr-light-5);
      }

      p {
        color: var(--clr-light-5);
        text-align: center;
      }
    }

    @media (max-width: 768px) {
      grid-template-columns: 1fr;
    }

    @media (min-width: 768px) {
      grid-template-columns: 1fr 1fr;
    }
    @media (min-width: 992px) {
      grid-template-columns: 1fr 1fr 1fr;
    }
  }

  .button {
    position: relative;
    margin-top: 5rem;

    .btn {
      position: absolute;
      top: 50%;
      left: 50%;
      -ms-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);
    }
  }



  }

`
export default Movies
