import React from "react"
import styled from "styled-components"
// import { Link } from "gatsby"

const Footer = () => {
  return (
    <Wrapper className="section">
      <footer className="section-center">
        <p>
          <a href="#logo" className="yellow-color">
            Vurt
          </a>{" "}
          &copy; {new Date().getFullYear()}{" "}
          <a
            href="https://pixeljuice.hu"
            target="_blank"
            rel="noreferrer"
            className="pixeljuice"
          >
            PixelJuice
          </a>
        </p>
        <a
          href="https://icons8.com/"
          target="_blank"
          rel="noreferrer"
          className="small"
        >
          Icons by Icons8
        </a>
      </footer>
    </Wrapper>
  )
}
const Wrapper = styled.footer`
  height: 5rem;
  display: grid;
  place-items: center;
  background: var(--clr-dark-2);
  text-align: center;
  padding: 1rem;
  p {
    color: var(--clr-light-5);
    margin-bottom: 0;

    .yellow-color {
      color: var(--clr-secondary-5);
    }

    .pixeljuice {
      text-decoration: none;
      color: var(--clr-light-3);
    }

    @media (max-width: 576px) {
      font-size: 0.75rem;
    }
  }

  .small {
    font-size: 0.8rem;
    text-decoration: none;
    color: var(--clr-light-3);
    @media (max-width: 576px) {
      font-size: 0.6rem;
    }
  }

  a {
    display: inline;
  }

  a:hover {
    color: var(--clr-light-10);
  }
`
export default Footer
