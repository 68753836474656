import React from "react"
import styled from "styled-components"
import { GiQuill } from "react-icons/gi"

const Title = ({ title }) => {
  return (
    <Wrapper>
      <h2>
        <span>
          <GiQuill />
        </span>{" "}
        {title}
      </h2>
    </Wrapper>
  )
}
const Wrapper = styled.div`
  text-align: center;
  margin-bottom: 2rem;
  h2 {
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 500;
    padding-top: 1rem;
    color: var(--clr-tertiary-10);

    span {
      font-size: 1.2em;
      color: var(--clr-secondary-5);
      margin-right: 1rem;
      font-weight: 900;
    }
  }
`
export default Title
