import React from "react"
import { Link } from "gatsby"
import Title from "./Title"
// import { GatsbyImage } from "gatsby-plugin-image"

import styled from "styled-components"
// import { clearConfigCache } from "prettier"

const slugifyPost = require("../../slugifyPost")

const Authors = ({ authors: data, title, page }) => {
  const [authors] = React.useState(data)

  return (
    <Wrapper className="section">
      <Title title={title || "Szerzők"} />

      <div className="section-center">
        {authors.map(item => {
          const {
            authorFirstName,
            authorMiddleName,
            authorLastName,
            authorFullName,
            contentful_id,
            createdAt,
          } = item

          return (
            <article key={contentful_id} className="card">
              <Link
                // to={`/leftover/poems/${slugifyPost(poems_title)}`}
                to={`/translation/author/${createdAt}-${slugifyPost(
                  authorFullName
                )}`}
                className="slug"
              >
                <h3>
                  {authorLastName}, {authorFirstName} {authorMiddleName}
                </h3>
              </Link>
            </article>
          )
        })}
      </div>
      {/*  csak akkor látható a button, ha az index-en vagyunk az összes versek oldalon nem */}
      <div className="button">
        {!page && (
          <Link to="/translation/books/1" className="btn">
            Összes könyv
          </Link>
        )}
      </div>
    </Wrapper>
  )
}

const Wrapper = styled.section`
  background: var(--clr-dark-5);
  padding-top: 3rem;

  .section-center {
    margin-top: 4rem;
    max-width: var(--max-width);
    display: grid;
    gap: 2rem;
    /* safari workaround */
    grid-gap: 2rem;

    .card {
      box-shadow: var(--light-shadow);
      border-radius: var(--radius);
      transition: var(--transition);
      position: relative;
      overflow: hidden;
      border-radius: var(--radius);
      background: var(--clr-primary-5);

      color: var(--clr-light-5);

      &:hover {
        box-shadow: var(--dark-shadow);
        background-color: var(--clr-tertiary-5);
        color: var(--clr-primary-5);
      }

      &:hover .img {
        opacity: 0.2;
      }

      .slug {
        display: block;
        width: 9rem;
        text-align: center;
        margin: 0 auto;
        width: auto;

        .img {
          border-radius: var(--radius);
          transition: var(--transition);
        }
        .info {
          padding: 1rem 0.5rem 0.5rem 0.5rem;
          background-color: var(--clr-dark-opacity);
          position: absolute;
          top: 40%;
          left: 50%;
          transform: translate(-50%, -50%);
          width: 100%;
          opacity: 0;
          transition: var(--transition);
          text-align: center;
          p {
            margin-bottom: 0.5rem;
          }
        }

        &:hover .info {
          opacity: 1;
        }
      }

      h3 {
        text-decoration: none;
        text-align: center;
        padding-top: 1rem;

        color: var(--clr-light-5);
        &:hover {
          color: var(--clr-primary-3);
        }
      }
      p {
        color: var(--clr-light-8);
        text-align: center;
      }
    }

    @media (min-width: 576px) {
      grid-template-columns: 1fr;
    }

    @media (min-width: 768px) {
      grid-template-columns: 1fr 1fr;
    }
    @media (min-width: 992px) {
      grid-template-columns: 1fr 1fr 1fr;
    }
  }

  .button {
    position: relative;
    margin-top: 5rem;

    .btn {
      position: absolute;
      top: 50%;
      left: 50%;
      -ms-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);
    }
  }
`
export default Authors
