import React from "react"
import styled from "styled-components"
import { StaticImage } from "gatsby-plugin-image"

const Hero = () => {
  return (
    <Wrapper>
      <header className="hero">
        <StaticImage
          src="../images/hero_1920.jpg"
          alt="letters"
          className="hero-img"
          placeholder="tracedSVG"
          layout="fullWidth"
        ></StaticImage>
        <div className="hero-container">
          <div className="hero-text">
            <blockquote className="quote">
              “Translators are the shadow heroes of literature"
              {/* the often
              forgotten instruments that make it possible for different cultures
              to talk to one another, who have enabled us to understand that we
              all, from every part of the world, live in one world.” */}
            </blockquote>
            <h4 className="source">- Paul Auster -</h4>
          </div>
        </div>
      </header>
    </Wrapper>
  )
}

const Wrapper = styled.section`
  .hero {
    /* az important a password modul miatt kell, e nélkül szétesik a hero */
    height: 50vh;
    position: relative;
    margin-bottom: 0rem;
    .hero-img {
      height: 100%;
      border-radius: var(--borderRadius);
    }

    .hero-container {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      background: rgba(0, 0, 0, 0.75);
      border-radius: var(--borderRadius);

      .hero-text {
        color: var(--clr-secondary-5);
        padding-top: 10%;
        line-height: 1.5;
        width: 80%;

        .quote {
          padding-bottom: 1.5rem;
          text-align: center;
          font-family: var(--ff-monospace);
          font-size: 1.8rem;
          font-weight: bold;
          text-transform: uppercase;
        }

        h4.source {
          text-align: right;
          padding-right: 20%;
          ${"" /* text-transform: uppercase; */}
          font-weight: bold;
          font-size: 1.5rem;
        }
      }
    }

    /* Small devices (landscape phones, 576px and up) */
    @media (min-width: 576px) {
      .quote {
        font-size: 1.2rem;
        h4 {
          font-size: 1rem;
        }
      }
    }

    /* Medium devices (tablets, 768px and up) */
    @media (min-width: 768px) {
    }

    /* Large devices (desktops, 992px and up) */
    @media (min-width: 992px) {
    }

    /* X-Large devices (large desktops, 1200px and up) */
    @media (min-width: 1200px) {
      .hero-text {
        padding-bottom: 5%;
        padding-left: 10%;
        padding-right: 10%;
        text-align: center;

        h4 {
          padding-top: 1rem;
        }
      }
    }

    /* XX-Large devices (larger desktops, 1400px and up) */
    @media (min-width: 1400px) {
    }
  }
`

export default Hero
