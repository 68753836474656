import React from "react"
import styled from "styled-components"

const Video = ({ videoSrcURL, videoTitle, ...props }) => (
  <Wrapper className="section">
    <div className="video">
      <iframe
        src={videoSrcURL}
        title={videoTitle}
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture;
        fullscreen;
        "
        frameBorder="0"
        webkitallowfullscreen="true"
        mozallowfullscreen="true"
        height="540px"
        width="960px"
      />
    </div>
  </Wrapper>
)

const Wrapper = styled.section`
  .video {
    overflow: hidden;
    /* 16:9 aspect ratio */
    padding-top: 56.25%;
    position: relative;
  }

  .video iframe {
    border: 0;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }

  ${
    "" /* .video iframe 
    width: 100%;
    height: 100%;
  }
  .video {
    height: 100%;
    width: 100%;
  } */
  }
`

export default Video
