import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"

const Pagination = ({ pageContext }) => {
  const { numPages, currentPage, prefix } = pageContext
  const isFirst = currentPage === 1
  const isLast = currentPage === numPages
  const prevPage =
    currentPage - 1 === 1
      ? prefix + 1
      : prefix + `${currentPage - 1}`.toString()
  const nextPage = (prefix + (currentPage + 1)).toString()

  //   console.log("Paginationjs  data", data)
  //   console.log("Paginationjs pagecontext", pageContext)

  return (
    <Wrapper className="section">
      <div className="section-center">
        <nav>
          <ul className="pagination">
            {!isFirst && (
              <Link to={prevPage} rel="prev" className="page-link">
                {"<<"}
              </Link>
            )}
            {Array.from({ length: numPages }, (_, i) => (
              <li key={`pagination-number${i + 1}`} className="page-item">
                <Link
                  to={prefix + `${i === 0 ? 1 : i + 1}`}
                  //   to={`${i === 0 ? "" : i + 1}`}
                  className="page-link"
                  activeClassName="active"
                >
                  {i + 1}
                </Link>
              </li>
            ))}
            {!isLast && (
              <Link to={nextPage} rel="next" className="page-link">
                {">>"}
              </Link>
            )}
          </ul>
        </nav>
      </div>
    </Wrapper>
  )
}

const Wrapper = styled.section`
  background: transparent;
  padding-bottom: 4rem;
  padding-top: 0rem;

  .section-center {
    nav {
      display: flex;
      justify-content: center;
      background: transparent;
      margin: 0 auto;

      .pagination {
        display: flex;
      }

      .pagination a {
        background: var(--clr-primary-5);
        color: var(--clr-tertiary-10);
        float: left;
        padding: 8px 16px;
        text-decoration: none;
        border: 1px solid var(--clr-primary-1);
        margin: 1px;
      }

      .pagination a.active {
        background: var(--clr-secondary-5);
        color: var(--clr-dark-1);
        border: 1px solid var(--clr-secondary-1);
      }

      .pagination a:hover:not(.active) {
        ${"" /* background: var(--clr-secondary-5); */}
        ${"" /* color: var(--clr-dark-1); */}

        background: var(--clr-tertiary-5);
        color: var(--clr-dark-1);
      }

      .pagination a:first-child {
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
      }

      .pagination a:last-child {
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
      }
    }
  }
`

export default Pagination
