import React from "react"
import { Link } from "gatsby"
import Title from "./Title"
import styled from "styled-components"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

const slugifyPost = require("../../slugifyPost")

const AudioAlbums = ({ albums: data, title, page }) => {
  const [albums] = React.useState(data)
  // more logic with setPoems-el....
  // console.log(poems)

  // console.log("audioalbums  data", data)

  return (
    <Wrapper className="section">
      <Title title={title || "Hanganyag"} />

      <div className="section-center">
        {albums.map(item => {
          const {
            contentful_id,
            audioAlbumTitle,
            performer,
            category,
            audioAlbumText,
          } = item

          const image = getImage(item.audioAlbumImage)

          return (
            <article key={contentful_id} className="card">
              <Link
                // to={`/leftover/poems/${slugifyPost(poems_title)}`}
                to={`/audio/${category.categoryName}/${slugifyPost(
                  performer.performerName
                )}-${slugifyPost(audioAlbumTitle)}`}
                className="slug"
              >
                <GatsbyImage image={image} className="img" alt="Album cover" />
                <div className="info">
                  <p
                    className=""
                    dangerouslySetInnerHTML={{
                      __html: audioAlbumText.childMarkdownRemark.excerpt,
                    }}
                  ></p>
                </div>
              </Link>

              <h3>{audioAlbumTitle}</h3>
              <p>{performer.performerName}</p>
            </article>
          )
        })}
      </div>
    </Wrapper>
  )
}

const Wrapper = styled.section`
  background: var(--clr-dark-5);
  padding-top: 3rem;

  .section-center {
    margin-top: 4rem;
    max-width: var(--max-width);
    display: grid;
    gap: 2rem;
    /* safari workaround */
    grid-gap: 2rem;

    .card {
      box-shadow: var(--light-shadow);
      border-radius: var(--radius);
      transition: var(--transition);
      position: relative;
      overflow: hidden;
      border-radius: var(--radius);
      background: var(--clr-primary-5);

      &:hover {
        box-shadow: var(--dark-shadow);
      }

      &:hover .img {
        opacity: 0.5;
      }

      .slug {
        display: block;
        width: 9rem;
        text-align: center;
        margin: 0 auto;
        width: auto;

        .img {
          ${"" /* height: 20rem; */}
          border-radius: var(--radius);
          transition: var(--transition);
        }

        .info {
          padding: 1rem 0.5rem 0.5rem 0.5rem;
          ${"" /* background: var(--clr-primary-5); */}
          ${"" /* background-color: rgba(42, 25, 25, 0.85); */}
          background-color: var(--clr-dark-opacity);
          position: absolute;
          top: 40%;
          left: 50%;
          transform: translate(-50%, -50%);
          width: 100%;
          opacity: 0;
          transition: var(--transition);
          text-align: center;
          p {
            margin-bottom: 0.5rem;
            ${"" /* color: var(--clr-light-3); */}
            ${"" /* text-transform: uppercase; */}
          }
        }
        &:hover .info {
          opacity: 1;
        }
      }
    }
    h3 {
      text-decoration: none;
      text-align: center;
      padding-top: 1rem;
      color: var(--clr-light-5);
    }
    p {
      color: var(--clr-light-8);
      text-align: center;
    }

    @media (max-width: 768px) {
      grid-template-columns: 1fr;
    }

    @media (min-width: 768px) {
      grid-template-columns: 1fr 1fr;
    }
    @media (min-width: 992px) {
      grid-template-columns: 1fr 1fr 1fr;
    }
  }

  ${
    "" /* a {
    display: block;
    width: 9rem;
    text-align: center;
    margin: 0 auto;
    color: var(--clr-light-3);
  } */
  }

  .button {
    position: relative;
    margin-top: 5rem;

    .btn {
      position: absolute;
      top: 50%;
      left: 50%;
      -ms-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);
    }
  }
`
export default AudioAlbums
