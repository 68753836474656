import React from "react"
import { Link } from "gatsby"
import Title from "./Title"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

import styled from "styled-components"
// import { clearConfigCache } from "prettier"
// import { clearConfigCache } from "prettier"

const slugifyPost = require("../../slugifyPost")

const Books = ({ books: data, title, page, link }) => {
  const [books] = React.useState(data)

  // console.log("books", books)

  return (
    <Wrapper className="section">
      <Title title={title || "Könyvek"} />
      <div className="section-center">
        {books.map(item => {
          const { contentful_id, bookTitle, author, bookText, bookYear } = item

          /* author.forEach(element => console.log(element.authorFullName)) */

          /* console.log("author", author) */

          /* ez lenne az új verzió de arrayhoz nem jó!*/
          const image = getImage(item.bookImage)

          /* let date = item.bookPublished */

          /* console.log("bookPublished", date) */

          /* const image =
            item.data.bookImage.localFiles[0].childImageSharp
              .gatsbyImageData */

          /* console.log(
            "excerpt",
            translation_book__text.childMarkdownRemark.excerpt
          ) */

          return (
            <article key={contentful_id} className="card">
              <Link
                // to={`/leftover/poems/${slugifyPost(poems_title)}`}
                to={`/${link}/book/${slugifyPost(
                  author.authorFullName
                )}-${slugifyPost(bookTitle)}`}
                className="slug"
              >
                <GatsbyImage image={image} className="img" alt="Book cover" />
                <div className="info">
                  <p
                    className=""
                    dangerouslySetInnerHTML={{
                      __html: bookText.childMarkdownRemark.excerpt,
                    }}
                  ></p>
                </div>
              </Link>
              <div className="book-data">
                <h3>{bookTitle}</h3>
                <h4>{author.authorFullName}</h4>
                <p>{bookYear.bookYear}</p>
              </div>
            </article>
          )
        })}
      </div>
      {/*  csak akkor látható a button, ha az index-en vagyunk az összes versek oldalon nem */}
      <div className="button">
        {!page && (
          <Link to="/translation/books/1" className="btn">
            Összes könyv
          </Link>
        )}
      </div>
    </Wrapper>
  )
}

const Wrapper = styled.section`
  background: var(--clr-dark-5);
  padding-top: 3rem;

  .section-center {
    margin-top: 4rem;
    max-width: var(--max-width);
    display: grid;
    gap: 2rem;
    /* safari workaround */
    grid-gap: 2rem;

    .card {
      box-shadow: var(--light-shadow);
      border-radius: var(--radius);
      transition: var(--transition);
      position: relative;
      overflow: hidden;
      border-radius: var(--radius);
      background: var(--clr-primary-5);

      &:hover {
        box-shadow: var(--dark-shadow);
      }

      &:hover .img {
        opacity: 0.2;
      }

      .slug {
        display: block;
        width: 9rem;
        text-align: center;
        margin: 0 auto;
        width: auto;

        .img {
          border-radius: var(--radius);
          transition: var(--transition);
        }
        .info {
          padding: 1rem 0.5rem 0.5rem 0.5rem;
          background-color: var(--clr-dark-opacity);
          position: absolute;
          top: 40%;
          left: 50%;
          transform: translate(-50%, -50%);
          width: 100%;
          opacity: 0;
          transition: var(--transition);
          text-align: center;
          p {
            margin-bottom: 0.5rem;
          }
        }

        &:hover .info {
          opacity: 1;
        }
      }
    }

    .book-data {
    }

    h3 {
      text-decoration: none;
      text-align: center;
      padding-top: 1rem;
      color: var(--clr-light-5);
      line-height: 2rem;
    }

    h4 {
      font-size: 1.2rem;
      text-align: center;
    }

    p {
      color: var(--clr-light-8);
      text-align: center;
    }

    @media (min-width: 576px) {
      grid-template-columns: 1fr 1fr;
    }

    @media (min-width: 768px) {
      grid-template-columns: 1fr 1fr 1fr;
    }
    @media (min-width: 992px) {
      grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    }
  }

  .button {
    position: relative;
    margin-top: 5rem;

    .btn {
      position: absolute;
      top: 50%;
      left: 50%;
      -ms-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);
    }
  }
`
export default Books
